//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO: https://lulububu.atlassian.net/browse/FIREWRITER-817

import React from 'react';

import AdBanner     from '@stateless/atomic/AdBanner';
import AdBannerSlot from '@stateless/atomic/AdBanner/AdBannerSlot';
import Headline     from '@stateless/atomic/Headline';

const AdvertisementTest = () => {
    return (
        <div>
            <Headline title={'Advertisement Test'} />
            <AdBanner
                slot={AdBannerSlot.ARCHIVE_BANNER}
                test={true}
            />
            <AdBanner
                slot={AdBannerSlot.LIST_BANNER}
                test={true}
            />
            <AdBanner
                slot={AdBannerSlot.STEP_3_BANNER}
                test={true}
            />
            <AdBanner
                slot={AdBannerSlot.STEP_7_BANNER}
                test={true}
            />
            <AdBanner
                slot={AdBannerSlot.HOME_RIGHT_BANNER}
                test={true}
            />
        </div>
    );
};

export default AdvertisementTest;
