//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n       from 'i18next';
import _          from 'lodash';
import { put }    from 'redux-saga/effects';
import { delay }  from 'redux-saga/effects';
import { select } from 'redux-saga/effects';
import { call }   from 'redux-saga/effects';

import * as Api               from '@api/index';
import Overlay                from '@constants/Overlay';
import Routes                 from '@constants/Routes';
import DateTime               from '@helper/DateTime';
import ErrorCodeTranslator    from '@helper/ErrorCodeTranslator';
import Notification           from '@helper/Notification';
import RouteHelper            from '@helper/Route';
import SagaStateHelper        from '@helper/SagaStateHelper';
import Token                  from '@helper/Token';
import { AlertBoxActions }    from '@slices/alertBox';
import { NavigationActions }  from '@slices/navigation';
import { OverlayActions }     from '@slices/overlay';
import { UserActions }        from '@slices/user';
import IconType               from '@stateless/atomic/Icon/IconType';
import { selectCustomerPlan } from '@store/selectors/user';
import { selectPlan }         from '@store/selectors/user';

const afterLoginRoute = Routes.home;

/**
 * All routes that are defined in this array are reachable without the
 * requirement of a active/valid session. If no session is present on
 * a route that is not in this array, the user is automatically redirected
 * to Routes.login (in the restoreToken-saga).
 *
 * @type {(string)[]}
 */
const routesWithoutSession = [
    Routes.login,
    Routes.advertisementTest, // TODO: https://lulububu.atlassian.net/browse/FIREWRITER-817
    Routes.resetPassword,
    Routes.lostPassword,
    Routes.activateTestAccount,
    Routes.registerTestAccount,
];

function* requestLogin(email, password) {
    yield put(AlertBoxActions.clearAlerts());

    const response = yield call(
        Api.context.user.login,
        email,
        password,
    );

    if (response.ok) {
        const { lastLogin, token, username, userId } = response.data;

        Api.context.user.setToken(token);

        yield put(UserActions.loginSucceeded({
            lastLogin,
            token,
            username,
            userId,
        }));
    } else {
        yield put(UserActions.loginFailed({
            error: _.get(response, 'data.error'),
        }));
    }
}

function* requestActivateTestAccount(iri, hash, password) {
    yield put(AlertBoxActions.clearAlerts());

    const response = yield call(
        Api.context.user.activateTestAccount,
        iri,
        hash,
        password,
    );

    if (response.ok) {
        yield put(UserActions.activateTestAccountSucceeded());
    } else {
        yield put(UserActions.activateTestAccountFailed());
    }
}

function* requestRegisterTestAccount(firstname, lastname, email, password) {
    yield put(AlertBoxActions.clearAlerts());

    const response = yield call(
        Api.context.user.registerTestAccount,
        firstname,
        lastname,
        email,
        password,
    );

    if (response.ok) {
        yield put(UserActions.registerTestAccountSucceeded());
    } else {
        yield put(UserActions.registerTestAccountFailed());
    }
}

function* login(action) {
    const { payload }         = action;
    const { email, password } = payload;
    const user                = yield SagaStateHelper.selectFromState('user');
    const emailToUse          = email || user.email;
    const passwordToUse       = password || user.password;

    yield requestLogin(emailToUse, passwordToUse);
}

function* loginWithCredentials(action) {
    const { email, password } = action.payload;

    yield put(UserActions.login({
        email,
        password,
    }));
}

function* loginFailed(action) {
    const errorCode = _.get(action, 'payload.error');

    yield put(AlertBoxActions.showErrorAlert({
        text: ErrorCodeTranslator.getTranslatedError(errorCode, 'loginError'),
    }));
}

function* activateTestAccount() {
    const user            = yield SagaStateHelper.selectFromState('user');
    const password        = user.password;
    const confirmPassword = user.confirmPassword;

    if (password !== confirmPassword) {
        yield put(UserActions.activateTestAccountFailed({
            error: 'passwordsDoNotMatch',
        }));

        return;
    }

    const { id, hash } = RouteHelper.getQueryParametersFromCurrentRoute('id', 'hash');

    yield requestActivateTestAccount(id, hash, password);
}

function* activateTestAccountSucceeded() {
    yield put(NavigationActions.redirect({
        route: Routes.login,
    }));

    yield put(AlertBoxActions.showSuccessAlertTranslated({
        textKey:     'notifications.testAccountActivated',
        lifeCounter: 2,
    }));
}

function* activateTestAccountFailed(action) {
    const errorCode = _.get(action, 'payload.error');

    yield put(AlertBoxActions.showErrorAlert({
        text: ErrorCodeTranslator.getTranslatedError(errorCode, 'testAccountCreationError'),
    }));
}

function* registerTestAccount(action) {
    const { firstname, lastname }              = _.get(action, 'payload');
    const user                                 = yield SagaStateHelper.selectFromState('user');
    const { password, confirmPassword, email } = user;

    if (password !== confirmPassword) {
        yield put(UserActions.registerTestAccountFailed({
            error: 'passwordsDoNotMatch',
        }));

        return;
    }

    yield requestRegisterTestAccount(
        firstname,
        lastname,
        email,
        password,
    );
}

function* registerTestAccountSucceeded() {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('successCodes.testAccountCreationSuccess'),
    }));
}

function* registerTestAccountFailed(action) {
    const errorCode = _.get(action, 'payload.error');

    yield put(AlertBoxActions.showErrorAlert({
        text: ErrorCodeTranslator.getTranslatedError(errorCode, 'testAccountCreationError'),
    }));
}

function* logout() {
    Api.context.user.setToken(null);
    yield put(OverlayActions.resetOverlays());
    yield put(NavigationActions.redirect({
        route: Routes.login,
    }));
}

function* logoutAfterTokenExpiration() {
    const userToken               = yield SagaStateHelper.selectFromState('user', 'token');
    const timeTillExpiration      = Token.getTimeTillExpiration(userToken);
    const daysToWaitForExpiration = 7;
    const sevenDaysInMilliseconds = DateTime.getDaysInMilliseconds(daysToWaitForExpiration);

    if (timeTillExpiration < sevenDaysInMilliseconds) {
        console.log('User: waiting for the token to expire up to:', `${daysToWaitForExpiration} days`);

        yield delay(timeTillExpiration);
        yield put(UserActions.logout());
    } else {
        console.log('User: skip waiting for the token to expire because it is more than:', `${daysToWaitForExpiration} days`);
    }
}

function* loginSucceeded() {
    yield put(NavigationActions.redirect({
        route: afterLoginRoute,
    }));
    yield* logoutAfterTokenExpiration();
}

function* restoreToken() {
    const pathname        = SagaStateHelper.selectFromState(
        'router',
        'location',
        'pathname',
    );
    const browserPathname = window.location.pathname;
    const user            = yield SagaStateHelper.selectFromState('user');

    if (user.token) {
        if (Token.isValidJWTToken(user.token)) {
            Api.context.user.setToken(user.token);

            if (
                pathname === Routes.login ||
                browserPathname === Routes.login
            ) {
                yield put(NavigationActions.redirect({
                    route: afterLoginRoute,
                }));
            }

            yield* logoutAfterTokenExpiration();
        } else {
            yield put(UserActions.logout());
        }
    } else if (
        routesWithoutSession.indexOf(pathname) === -1 &&
        routesWithoutSession.indexOf(browserPathname) === -1
    ) {
        // This delay is important otherwise the redirect will not work properly
        yield delay(50);
        yield put(NavigationActions.redirect({
            route: Routes.login,
        }));
    }
}

function* fetchUser() {
    const userIri  = yield SagaStateHelper.selectFromState('user', 'iri');
    const response = yield call(
        Api.context.user.fetchUser,
        userIri,
    );

    if (response.ok) {
        const userProfile = _.get(response, 'data');

        yield put(UserActions.fetchUserSucceeded({
            userProfile,
        }));
    } else {
        yield put(UserActions.fetchUserFailed());
    }
}

function* fetchUserFailed() {
    console.log('fetchUserFailed: Failed to fetch user data');
}

function* fetchUserSucceeded() {
    console.log('fetchUserSucceeded: Successfully fetched user data');
}

function* fetchCustomerPlan() {
    const response = yield call(
        Api.context.customerPlan.fetchCustomerPlan,
        yield select((state) => selectCustomerPlan(state)),
    );

    if (response.ok) {
        const customerPlan = _.get(response, 'data');

        yield put(UserActions.fetchCustomerPlanSucceeded({
            customerPlan,
        }));
    } else {
        yield put(UserActions.fetchCustomerPlanFailed());
    }
}

function* updateOrganization(action) {
    const organization     = yield SagaStateHelper.selectFromState('user', 'userProfile', 'organization');
    const organizationName = _.get(action, 'payload.organizationName');

    if (
        organizationName == null ||
        !_.trim(organizationName)
    ) {
        yield put(UserActions.updateOrganizationFailed({
            errorKey: 'invalidOrganizationName',
        }));

        return;
    }

    const newOrganization = {
        ...organization,
        name: organizationName,
    };
    const response        = yield call(Api.context.organization.update, organization.id, newOrganization);

    if (response.ok) {
        yield put(UserActions.updateOrganizationSucceeded({
            organization: response.data,
        }));
    } else {
        yield put(UserActions.updateOrganizationFailed());
    }
}

function* updateOrganizationSucceeded() {
    Notification.success('updateOrganisationSucceeded');
    yield put(AlertBoxActions.showSuccessAlertTranslated({
        textKey: 'notifications.updateOrganisationSucceeded',
    }));
}

function* updateOrganizationFailed(action) {
    const errorKey = _.get(action, 'payload.errorKey', 'updateOrganisationFailed');

    Notification.error(errorKey);
    yield put(AlertBoxActions.showErrorAlertTranslated({
        textKey:  `notifications.${errorKey}`,
        iconLeft: IconType.danger,
    }));
}

function* updatePassword(action) {
    const iri         = yield SagaStateHelper.selectFromState('user', 'iri');
    const newPassword = _.get(action, 'payload.password');
    const oldPassword = _.get(action, 'payload.oldPassword');
    const response    = yield call(Api.context.user.updatePassword, iri, newPassword, oldPassword);

    if (response.ok) {
        yield put(UserActions.updatePasswordSucceeded());
    } else {
        yield put(UserActions.updatePasswordFailed());
    }
}

function* updatePasswordFailed() {
    Notification.error('updatePasswordFailed');
    yield put(AlertBoxActions.showErrorAlertTranslated({
        textKey:  'notifications.updatePasswordFailed',
        iconLeft: IconType.danger,
    }));
}

function* updatePasswordSucceeded() {
    Notification.success('updatePasswordSucceeded');
    yield put(AlertBoxActions.showSuccessAlertTranslated({
        textKey: 'notifications.updatePasswordSucceeded',
    }));
}

function* updateNotificationSettings() {
    const user         = yield SagaStateHelper.selectFromState('user');
    const userIri      = _.get(user, 'iri');
    const userData     = _.pick(
        user.userProfile,
        [
            'email',
            'firstname',
            'name',
            'emailNotificationsEnabled',
        ],
    );
    const userResponse = yield call(Api.context.user.update, userIri, userData);

    if (userResponse.ok) {
        yield put(UserActions.updateNotificationSettingsSucceeded());
    } else {
        yield put(UserActions.updateNotificationSettingsFailed());
    }
}

function* updateNotificationSettingsSucceeded() {
    Notification.success('updateNotificationSettingsSucceeded');
    yield put(AlertBoxActions.showSuccessAlertTranslated({
        textKey: 'notifications.updateNotificationSettingsSucceeded',
    }));
}

function* updateNotificationSettingsFailed() {
    Notification.error('updateNotificationSettingsFailed');
    yield put(AlertBoxActions.showErrorAlertTranslated({
        textKey:  'notifications.updateNotificationSettingsFailed',
        iconLeft: IconType.danger,
    }));
}

function* updateUser(action) {
    const payload     = _.get(action, 'payload');
    const user        = yield SagaStateHelper.selectFromState('user');
    const { changes } = user;
    const userIri     = _.get(user, 'iri');
    const userData    = _.omit(
        payload,
        [
            'avatar',
            'loadingLevel',
            'text',
        ],
    );

    if (user.userProfile.uploadedAvatar) {
        userData.avatar = user.userProfile.uploadedAvatar;
    }

    const userResponse = yield call(Api.context.user.update, userIri, userData);

    if (userResponse.ok) {
        yield put(UserActions.updateUserSucceeded({
            userProfile: userResponse.data,
        }));

        if (changes.email) {
            yield put(UserActions.logout());
        } else if (changes.avatar) {
            yield put(UserActions.fetchUser());
        }
    } else {
        yield put(UserActions.updateUserFailed());
    }
}

function* updateUserFailed() {
    Notification.error('updateUserFailed');
    yield put(AlertBoxActions.showErrorAlertTranslated({
        textKey:  'notifications.updateUserFailed',
        iconLeft: IconType.danger,
    }));
}

function* updateUserSucceeded() {
    Notification.success('updateUserSucceeded');
    yield put(AlertBoxActions.showSuccessAlertTranslated({
        textKey: 'notifications.updateUserSucceeded',
    }));
}

function* resetPassword() {
    const email    = yield SagaStateHelper.selectFromState('user', 'email');
    const response = yield call(Api.context.user.resetPassword, email);

    if (response.ok) {
        yield put(UserActions.resetPasswordSucceeded());
    } else {
        yield put(UserActions.resetPasswordFailed());
    }
}

function* resetPasswordFailed() {
    yield put(AlertBoxActions.showErrorAlertTranslated({
        textKey:  'notifications.resetPasswordFailed',
        iconLeft: IconType.danger,
    }));
}

function* resetPasswordSucceeded() {
    yield put(AlertBoxActions.showSuccessAlertTranslated({
        textKey: 'notifications.resetPasswordSucceeded',
    }));
}

function* setNewPassword(action) {
    const token    = _.get(action, 'payload.token');
    const password = yield SagaStateHelper.selectFromState('user', 'password');
    const response = yield call(Api.context.user.setNewPassword, token, password);

    if (response.ok) {
        yield put(UserActions.setNewPasswordSucceeded());
    } else {
        yield put(UserActions.setNewPasswordFailed());
    }
}

function* setNewPasswordFailed() {
    yield put(AlertBoxActions.showErrorAlertTranslated({
        textKey:  'notifications.setNewPasswordFailed',
        iconLeft: IconType.danger,
    }));
}

function* setNewPasswordSucceeded() {
    yield put(NavigationActions.redirect({
        route: Routes.login,
    }));

    yield put(AlertBoxActions.showSuccessAlertTranslated({
        textKey:     'notifications.setNewPasswordSucceeded',
        lifeCounter: 2,
    }));
}

function* terminatePaidPlan() {
    const plan = yield SagaStateHelper.selectFromStateBySelector(selectPlan);

    if (plan.defaultPlan === true) {
        Notification.error('noPaidPlanSubscribed');

        return;
    }

    yield put(OverlayActions.openOverlay({
        overlay: Overlay.confirmPaidPlanTermination,
    }));
}

function* terminatePaidPlanConfirmed(action) {
    const response = yield call(Api.context.user.terminatePlan);

    if (response.ok) {
        yield put(UserActions.terminatePaidPlanConfirmedSucceeded());
    } else {
        const errorKey = _.get(response, 'data.error');

        yield put(OverlayActions.closeOverlay());
        yield put(UserActions.terminatePaidPlanConfirmedFailed({
            errorKey,
        }));
    }
}

function* terminatePaidPlanSucceeded() {
    yield put(OverlayActions.closeOverlay());
    yield put(OverlayActions.openOverlay({
        overlay: Overlay.successfullyTermination,
    }));
}

function* terminatePaidPlanFailed(action) {
    const { payload: { errorKey } } = action;
    const errorMessageKey           = Notification.translationExistsForKey(errorKey)
        ? errorKey
        : 'terminatePaidPlanFailed';

    Notification.error(errorMessageKey);
    yield put(AlertBoxActions.showErrorAlertTranslated({
        textKey:  `notifications.${errorMessageKey}`,
        iconLeft: IconType.danger,
    }));
}

export default {
    fetchCustomerPlan,
    fetchUser,
    fetchUserFailed,
    fetchUserSucceeded,
    login,
    loginFailed,
    loginSucceeded,
    loginWithCredentials,
    activateTestAccount,
    activateTestAccountSucceeded,
    activateTestAccountFailed,
    logout,
    restoreToken,
    updateOrganization,
    updateOrganizationFailed,
    updateOrganizationSucceeded,
    updatePassword,
    updatePasswordFailed,
    updatePasswordSucceeded,
    updateUser,
    updateUserFailed,
    updateUserSucceeded,
    resetPassword,
    resetPasswordFailed,
    resetPasswordSucceeded,
    setNewPassword,
    setNewPasswordFailed,
    setNewPasswordSucceeded,
    registerTestAccount,
    registerTestAccountFailed,
    registerTestAccountSucceeded,
    updateNotificationSettingsFailed,
    updateNotificationSettingsSucceeded,
    updateNotificationSettings,
    terminatePaidPlan,
    terminatePaidPlanConfirmed,
    terminatePaidPlanFailed,
    terminatePaidPlanSucceeded,
};
