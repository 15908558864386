//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = Object.freeze({
    activateTestAccount:                         'Testaccount aktivieren',
    addExternalUnit:                             'Externe Einsatzkraft hinzufügen',
    and:                                         'und',
    autoLogin:                                   'Auto Login',
    closeMonths:                                 'Monate schließen',
    closeYears:                                  'Jahre schließen',
    confirmExit:                                 'Schließen',
    confirmExitAbort:                            'Fortfahren',
    confirmPassword:                             'Passwort bestätigen',
    dateTimeFormat:                              'DD.MM.YYYY HH:mm:ss',
    deletingExternalUnit:                        'Lösche externe Einsatzkraft',
    deletingUnit:                                'Lösche Einheit',
    deletingVehicle:                             'Lösche Fahrzeug',
    deletingParticularity:                       'Lösche Besonderheit',
    creatingParticularity:                       'Besonderheit wird hinzugefügt',
    updatingParticularity:                       'Besonderheit wird aktualisiert',
    disabledSocialMediaButtonText:               'Wir arbeiten derzeit intensiv an der Entwicklung dieses <strong>Features</strong>. Sei gespannt, wir werden dich stets auf dem Laufenden halten und dir alle Neuigkeiten präsentieren.',
    emailAddress:                                'E-Mailadresse',
    emailAddressPlaceholder:                     'info@firewriter.de',
    emergencyPersonnelCount:                     'Einsatzkräfte eingesetzt',
    externalEmergencyPersonnel:                  'Externe Einsatzkräfte',
    forgotPassword:                              'Passwort vergessen?',
    from:                                        'von',
    wordpressUrl:                                'https://firewriter.de/',
    hello:                                       'Hallo',
    help:                                        'Hilfe',
    hour:                                        'Stunde',
    imprint:                                     'Impressum',
    loadingLogin:                                'Lade Login...',
    login:                                       'Anmelden',
    loginScreenText:                             'Hallo, schön dich zu sehen.',
    logout:                                      'Logout',
    lostPassword:                                'Neues Passwort setzen',
    lostPasswordScreenPasswordMismatch:          'Passwörter stimmen nicht überein',
    lostPasswordScreenText:                      'Neues Passwort setzen',
    minute:                                      'Minute',
    more:                                        'Mehr',
    nextMonth:                                   'Nächster Monat',
    no:                                          'Nein',
    oclock:                                      'Uhr',
    openMonths:                                  'Monate öffnen',
    openYears:                                   'Jahre öffnen',
    or:                                          'oder',
    pageActivateTestAccount:                     'Testaccount aktivieren',
    pageRegisterTestAccount:                     'Testaccount anlegen',
    pageHome:                                    'Home',
    pageLogin:                                   'Login',
    pageInvoicesAndPlans:                        'Pläne und Rechnungen',
    pageLostPassword:                            'Neues Passwort setzen',
    pageResetPassword:                           'Passwort zurücksetzen',
    pageTitle:                                   'Firewriter',
    password:                                    'Passwort',
    passwordPlaceholder:                         'Passwort',
    passwordRepeat:                              'Passwort wiederholen',
    passwordRepeatPlaceholder:                   'Passwort',
    personsRecoveredDead:                        'Person tot geborgen',
    personsRecoveredDead_plural:                 'Personen tot geborgen',
    personsRescued:                              'Person gerettet',
    personsRescued_plural:                       'Personen gerettet',
    previousMonth:                               'Vorheriger Monat',
    privacy:                                     'Datenschutz',
    profile:                                     'Profil',
    resetPassword:                               'Passwort zurücksetzen',
    resetPasswordScreenText:                     'Passwort zurücksetzen',
    resettingPassword:                           'Sende E-Mail...',
    search:                                      'Suche...',
    select:                                      'Auswählen',
    setNewPassword:                              'Neues Passwort wird gesetzt...',
    settings:                                    'Einstellungen',
    stagingPasswordPlaceholder:                  'Staging-Passwort',
    stayLoggedIn:                                'Angemeldet bleiben',
    testAccountNoWordsLeftButtonConsultation:    'Beratungsgespräch vereinbaren',
    testAccountNoWordsLeftButtonConsultationSub: 'Unverbindlich & kostenlos',
    testAccountNoWordsLeftButtonOrder:           'Jetzt hier bestellen!',
    testAccountNoWordsLeftHeader:                'Deine Wörter sind bald aufgebraucht.',
    testAccountNoWordsLeftText:                  'Wir möchten uns bereits jetzt bei dir für die Nutzung des Testaccounts bedanken! Wir hoffen, du hast einen umfassenden Einblick in die vielfältigen Funktionen von FireWriter erhalten. Solltest du weiterhin Interesse an unserem Produkt haben, kannst du entweder direkt über unser Bestellformular bestellen oder ein kostenloses Beratungsgespräch vereinbaren. Wir sind jederzeit für Dich da und unterstützen gerne bei der Wahl des passenden Pakets.',
    to:                                          'bis',
    unknown:                                     'Unbekannt',
    updatingExternalUnit:                        'Aktualisiere externe Einsatzkraft',
    creatingExternalUnit:                        'Füge externe Einsatzkraft hinzu',
    updatingOrganization:                        'Aktualisiere Organisation',
    updatingPassword:                            'Aktualisiere Passwort',
    updatingUnit:                                'Aktualisiere Einheit',
    creatingUnit:                                'Füge Einheit hinzu',
    progressTermination:                         'Abo wird gekündigt',
    updatingUser:                                'Aktualisiere Benutzer',
    updatingVehicle:                             'Aktualisiere Fahrzeug',
    updateNotifications:                         'Benachrichtigungen werden aktualisiert...',
    creatingVehicle:                             'Füge Fahrzeug hinzu',
    versionNumberDateFormat:                     'DD.MM.YYYY HH:mm:ss',
    webAppCancel:                                'Abbrechen',
    webAppInstall:                               'Installieren',
    webAppInstallText:                           'Sie können diese Anwendung installieren, um sie dauerhaft offline verwenden zu können.',
    webAppOk:                                    'OK',
    windowSize:                                  'Fenstergröße',
    yes:                                         'Ja',
    emailVerifiedText:                           'Deine E-Mailadresse wurde erfolgreich verifiziert. Du kannst dich nun mit deinen Zugangsdaten einloggen.',
    activateTestAccountText:                     {
        firstParagraph:  'nur noch ein Schritt bis zur Aktivierung deines Testaccounts. Vergib ein Passwort und du kannst direkt starten.',
        secondParagraph: 'Solltest du dich in Zukunft einloggen wollen, so benötigst du nur deine E-Mail-Adresse und das jetzt von dir gewählte Passwort.',
    },
    errorCodes:                                  {
        noOrganization:           'Du kannst dich mit diesem Account nicht einloggen da du keiner Organisation zugewiesen bist.',
        loginError:               'Der Login ist leider fehlgeschlagen. Bitte überprüfen Sie Ihre Zugangsdaten und versuchen es erneut.',
        passwordsDoNotMatch:      'Die Passwörter stimmen nicht überein.',
        testAccountCreationError: 'Die Erstellung des Testaccounts ist leider fehlgeschlagen. Bitte versuchen Sie es erneut.',
    },
    successCodes:                                {
        testAccountCreationSuccess: 'Der Testaccount wurde erfolgreich erstellt. Bitte überprüfen Sie Ihre E-Mails um den Testaccount zu aktivieren.',
    },
    screens:                                     {
        notFound:            {
            title:                'Seite nicht gefunden',
            alternativeImageText: 'Feuerwehrmann',
            buttonText:           'Zur Startseite',
            firstHeadline:        '404',
            secondHeadline:       'Seite nicht gefunden...',
            pageTitle:            'Seite nicht gefunden',
        },
        registerTestAccount: {
            firstname:                     'Vorname',
            lastname:                      'Nachname',
            firstnamePlaceholder:          'Michael',
            lastnamePlaceholder:           'Mayer',
            email:                         'E-Mailadresse',
            emailPlaceholder:              'info@firewriter.de',
            password:                      'Passwort',
            passwordPlaceholder:           'Passwort',
            confirmPassword:               'Passwort wiederholen',
            confirmPasswordPlaceholder:    'Passwort',
            activateTestAccount:           'Testaccount aktivieren',
            registerTestAccountText:       'Mit FireWriter und KI zum perfekten Pressebericht - sichere Dir jetzt Deinen kostenfreien Test-Account! Anmelden & loslegen!',
            termsAndConditionsPrivacyText: 'Ich habe die folgenden Dokumente gelesen und akzeptiere diese: <0>Allgemeine Geschäftsbedingungen</0> und <1>Datenschutzerklärung</1>',
            termsAndConditionsUrl:         'https://firewriter.de/agb/',
            privacyUrl:                    'https://firewriter.de/datenschutz/',
        },
    },
    loadingText:                                 {
        fetchPressArticles:     'Lade Presseartikel...',
        fetchUser:              'Lade Nutzer-Daten...',
        createPressArticle:     'Wir erstellen gerade deinen individuellen Presseartikel',
        savePressArticle:       'Presseartikel wird gespeichert...',
        updatePressArticle:     'Presseartikel wird aktualisiert...',
        deletePressArticle:     'Presseartikel wird gelöscht...',
        generateHeadline:       'Überschrift wird generiert...',
        generateText:           'Text wird generiert...',
        activatingTestAccount:  'Testaccount wird aktiviert...',
        registeringTestAccount: 'Testaccount wird erstellt...',
    },
    notifications:                               {
        updateNotificationSettingsFailed:    'Fehler beim Aktualisieren der Benachrichtigen',
        updateNotificationSettingsSucceeded: 'Benachrichtigen erfolgreich aktualisiert',
        createPressArticleFailed:            'Fehler beim Erstellen',
        createPressArticleSucceeded:         'Erfolgreich erstellt',
        deletePressArticleFailed:            'Fehler beim Löschen',
        deletePressArticleSucceeded:         'Erfolgreich gelöscht',
        generateHeadlineFailed:              'Überschrift konnte nicht generiert werden',
        generateHeadlineSucceeded:           'Überschrift wurde generiert',
        generateTextFailed:                  'Text konnte nicht generiert werden',
        generateTextSucceeded:               'Text wurde generiert',
        terminatePaidPlanFailed:             'Leider ist ein Fehler beim Kündigen des Abos aufgetreten. Bitte versuche es erneut, oder melde dich bei unserem Support.',
        headlineAndTextMustBeChanged:        'Überschrift oder Text ist ungültig!',
        noWordsLeftCustomerPlan:             'Du hast dein Kontingent an Wörtern für diesen Monat erreicht. Bitte wende dich an deinen Ansprechpartner.',
        noArticlesLeftCustomerPlan:          'Du hast dein Kontingent an Presseartikeln für diesen Monat erreicht. Bitte wende dich an deinen Ansprechpartner.',
        resetPasswordFailed:                 'Fehler beim Zurücksetzen des Passworts',
        resetPasswordSucceeded:              'Passwort erfolgreich zurückgesetzt - Prüfe deine E-Mails.',
        savePressArticleFailed:              'Fehler beim Aktualisieren',
        savePressArticleSucceeded:           'Erfolgreich aktualisiert',
        setNewPasswordFailed:                'Fehler beim setzen des neuen Passwortes',
        setNewPasswordSucceeded:             'Neues Passwort wurde erfolgreich gesetzt',
        testAccountActivated:                'Dein Testaccount wurde erfolgreich aktiviert. Logge dich ein um loszulegen!',
        textCopied:                          'Text kopiert',
        updateExternalUnitFailed:            'Fehler beim Aktualisieren der externen Einsatzkraft',
        updateExternalUnitSucceeded:         'Externe Einsatzkraft erfolgreich aktualisiert',
        createExternalUnitFailed:            'Fehler beim Hinzufügen der externen Einsatzkraft',
        createExternalUnitSucceeded:         'Externe Einsatzkraft erfolgreich hinzugefügt',
        updateOrganisationFailed:            'Fehler beim Aktualisieren der Organisation',
        updateOrganisationSucceeded:         'Organisation erfolgreich aktualisiert',
        updatePasswordFailed:                'Fehler beim Aktualisieren des Passworts',
        updatePasswordSucceeded:             'Passwort erfolgreich aktualisiert',
        updateUnitFailed:                    'Fehler beim Aktualisieren der Einheit',
        updateUnitSucceeded:                 'Einheit erfolgreich aktualisiert',
        createUnitFailed:                    'Fehler beim Hinzufügen der Einheit',
        createUnitSucceeded:                 'Einheit erfolgreich hinzugefügt',
        createParticularitySucceeded:        'Besonderheit erfolgreich hinzugefügt',
        createParticularityFailed:           'Fehler beim Hinzufügen der Besonderheit',
        updateParticularityFailed:           'Fehler beim Aktualisieren der Besonderheit',
        updateParticularitySucceeded:        'Besonderheit erfolgreich aktualisiert',
        updateUserFailed:                    'Fehler beim Aktualisieren des Benutzers',
        updateUserSucceeded:                 'Benutzer erfolgreich aktualisiert',
        updateVehicleFailed:                 'Fehler beim Aktualisieren des Fahrzeugs',
        updateVehicleSucceeded:              'Fahrzeug erfolgreich aktualisiert',
        createVehicleFailed:                 'Fehler beim Hinzufügen des Fahrzeugs',
        createVehicleSucceeded:              'Fahrzeug erfolgreich hinzugefügt',
        deleteVehicleSucceeded:              'Fahrzeug erfolgreich gelöscht',
        deleteVehicleFailed:                 'Fehler beim Löschen des Fahrzeugs',
        deleteUnitSucceeded:                 'Einheit erfolgreich gelöscht',
        deleteUnitFailed:                    'Fehler beim Löschen der Einheit',
        deleteParticularitySucceeded:        'Besonderheit erfolgreich gelöscht',
        deleteParticularityFailed:           'Fehler beim Löschen der Besonderheit',
        deleteExternalUnitSucceeded:         'Externe Einsatzkraft erfolgreich gelöscht',
        deleteExternalUnitFailed:            'Fehler beim Löschen der externen Einsatzkraft',
        invalidOrganizationName:             'Der Name der Organisation ist ungültig.',
        noPaidPlanSubscribed:                'Du hast keinen kostenpflichtigen Plan abonniert.',
        planAlreadyTerminated:               'Dein Abo wurde bereits gekündigt.',
        uniqueUnitNameViolation:             'Es existiert bereits eine Einheit mit diesem Namen.',
        uniqueVehicleNameViolation:          'Es existiert bereits ein Fahrzeug mit diesem Namen.',
        uniqueExternalUnitNameViolation:     'Es existiert bereits eine externe Einsatzkraft mit diesem Namen.',
        uniqueParticularityNameViolation:    'Es existiert bereits eine Besonderheit mit diesem Namen.',
        listEntityLimitReached:              'Die maximale Anzahl an Einträgen wurde erreicht.',
    },
    components:                                  {
        datePicker:                     {
            cancel:     'Abbruch',
            selectDate: 'Datum auswählen',
            selectTime: 'Zeit auswählen',
            months:     {
                january:   'Januar',
                february:  'Februar',
                march:     'März',
                april:     'April',
                may:       'Mai',
                june:      'Juni',
                july:      'Juli',
                august:    'August',
                september: 'September',
                october:   'Oktober',
                november:  'November',
                december:  'Dezember',
            },
            weekDays:   {
                monday:         'Montag',
                mondayShort:    'M',
                tuesday:        'Dienstag',
                tuesdayShort:   'D',
                wednesday:      'Mittwoch',
                wednesdayShort: 'M',
                thursday:       'Donnerstag',
                thursdayShort:  'D',
                friday:         'Freitag',
                fridayShort:    'F',
                saturday:       'Samstag',
                saturdayShort:  'S',
                sunday:         'Sonntag',
                sundayShort:    'S',
            },
        },
        yourPlan:                       {
            words:    'Wörter',
            articles: 'Artikel',
            plans:    {
                testaccount: 'Demo-Account',
                standard:    'Standard',
                premium:     'FireWriter<br>Premium',
            },
            header:   'Dein <strong>Plan</strong>',
        },
        stepIndicatorToolTip:           {
            text: 'Schritt <strong>{{currentStep}}</strong> von {{steps}}',
        },
        assistantSelection:             {
            withAssistant:    'Mit Assistent',
            withoutAssistant: 'Ohne Assistent',
        },
        userProfilePreview:             {
            alternativeImageText: 'Profilbild',
        },
        assistantSelectionButton:       {
            alternativeImageText: 'Feuerwehrmann',
        },
        logo:                           {
            alternativeImageText: 'FireWriter Logo',
        },
        dashboardButton:                {
            createSocialMediaPosting: '<strong>Social-Media-Posting</strong> erstellen',
            createPressRelease:       '<strong>Pressebericht</strong> erstellen',
        },
        loadingOverlay:                 {
            title:                'Warte kurz',
            alternativeImageText: 'Feuerwehrmann',
        },
        sideMenu:                       {
            settingsButton:           'Einstellungen',
            profileButton:            'Profil',
            unitListButton:           'Einheiten',
            vehicleListButton:        'Fahrzeuge',
            particularitiesButton:    'Besonderheiten',
            externalTaskForcesButton: 'Externe Einsatzkräfte',
            helpButton:               'Hilfe',
            logoutButton:             'Logout',
            imprint:                  'Impressum',
            imprintLink:              'https://firewriter.de/Impressum',
            privacy:                  'Datenschutz',
            privacyLink:              'https://firewriter.de/datenschutz',
        },
        explanationCard:                {
            explanationCardTitle: 'Herzlich Willkommen bei FireWriter!',
            explanationCardText:  'Für einen optimalen Start bei FireWriter haben wir dir ein umfangreiches Video mit nützlichen Informationen zusammengestellt.',
        },
        pressArticleOverlay:            {
            confirmClose:         'Overlay schließen',
            confirmCloseText:     'Bist du sicher, dass du das Overlay schließen möchtest? Alle Änderungen gehen verloren.',
            saveDraftText:        'Möchtest du den Entwurf speichern?',
            saveDraftConfirmText: 'Speichern',
            saveDraftDenyText:    'Verwerfen',
            updateDraftText:      'Möchtest du die Änderungen speichern?',
            addExternalUnits:     'Externe Einsatzkraft hinzufügen',
            addOwnUnits:          'Einheit hinzufügen',
            addOwnVehicles:       'Fahrzeug hinzufügen',
            addParticularities:   'Besonderheit hinzufügen',
            finishButtonText:     'Pressebericht generieren',
            finishButtonTextEdit: 'Eingaben speichern',
            title:                'Pressebericht',
            page2:                {
                when:  {
                    title: 'Wann war die Alarmierung zum Einsatz?',
                    help:  'Gebe das Datum und die Uhrzeit der Alarmierung an, wenn diese im Pressebericht erwähnt werden soll.',
                },
                where: {
                    title:       'Wo befand sich die Einsatzstelle?',
                    help:        'Gebe den Straßennamen und Ort an, wenn dieser erwähnt werden soll.',
                    placeholder: 'Musterstraße in Musterstadt…',
                },
            },
            page3:                {
                title:       'Mit welchem Alarmstichwort wurdet Ihr alarmiert?',
                help:        'Gebe das Alarmstichwort bzw. die Alarmmeldung an. \n\n<strong>Beispiele:</strong> \n\nWohnungsbrand - Personen vermisst \n\nSchwerer Verkehrsunfall - Personen eingeklemmt',
                placeholder: 'Wohnungsbrand - Personen vermisst…',
            },
            page4:                {
                title:       'Über welche Leitstelle wurde alarmiert?',
                help:        'Gebe deine zuständige Leitstelle an, wenn diese im Pressebericht erwähnt werden soll.',
                placeholder: 'Integrierte Leitstelle Musterstadt',
            },
            page5:                {
                title:       'Lage bei Ankunft der ersten Einsatzkräfte?',
                help:        'Welche Lage haben die ersten Einsatzkräfte an der Einsatzstelle vorgefunden.',
                placeholder: 'Starke Rauchentwicklung aus dem 1. Obergeschoss. Mindestens eine Person vermisst…',
            },
            page6:                {
                title:       'Maßnahmen an der Einsatzstelle',
                help:        'Welche Maßnahmen haben die Einsatzkräfte an der Einsatzstelle durchgeführt',
                placeholder: 'Angriffstrupp zur Menschenrettung im Gebäude. Weiterer Atemschutztrupp zur Brandbekämpfung in der Wohnung…',
            },
            page7:                {
                title: 'Personen gerettet/geborgen',
                help:  'Wie viele Personen wurden gerettet oder tot geborgen.',
            },
            page8:                {
                title: 'Eingesetzte eigene Einheiten',
                help:  'Wähle die eigenen eingesetzten Einheiten aus.',
            },
            page9:                {
                title: 'Eingesetzte eigene Fahrzeuge',
                help:  'Wähle die eigenen eingesetzten Fahrzeuge aus.',
            },
            page10:               {
                title: 'Eingesetzte externe Einsatzkräfte',
                help:  'Wähle die extern eingesetzten Einsatzkräfte aus.',
            },
            page11:               {
                title:       'Anzahl der Einsatzkräfte',
                help:        'Wähle die Anzahl der im Einsatz befindlichen Einsatzkräfte.',
                placeholder: 'Wähle die Anzahl der im Einsatz befindlichen Einsatzkräfte.',
            },
            page12:               {
                title:       'Besonderheiten',
                help:        'Welche Besonderheiten sind noch wichtig für den Pressebericht.',
                placeholder: 'Die Bundesstraße 30 musste voll gesperrt werden. \n\nWarnmeldung über die App „Nina“ veröffentlicht…',
            },
        },
        editPressArticleOverlay:        {
            copyText:                           'Text kopieren',
            confirmRegenerateText:              'Achtung: bei der Neugenerierung werden dir weitere Wörter abgezogen.',
            confirmRegenerateConfirmButtonText: 'Text neu generieren',
            confirmRegenerateDenyButtonText:    'Abbrechen',
            delete:                             'Löschen',
            editData:                           'Eingaben bearbeiten',
            generateHeadline:                   'Überschrift generieren',
            regenerateText:                     'Text durch KI neu generieren',
            update:                             'Text speichern',
        },
        deleteDraftOverlay:             {
            deleteDraftText:              'Möchtest du den Entwurf wirklich löschen?',
            deleteDraftConfirmButtonText: 'Löschen',
            deleteDraftDenyButtonText:    'Abbrechen',
        },
        editOwnUnitOverlay:             {
            addOwnUnit:                     'Einheit hinzufügen',
            addVehicle:                     'Fahrzeug hinzufügen',
            addOwnUnitHint:                 'Hier kannst du eine <strong>Einheitsliste</strong> für deine Organisation erstellen. Du kannst zum Beispiel Führungsgruppen, Abteilungen, Atemschutzschleifen, Tauchergruppen usw. hinzufügen.',
            deleteOwnUnitText:              'Sind Sie sicher, dass Sie die Einheit löschen möchten?',
            deleteOwnUnitConfirmButtonText: 'Löschen',
            deleteOwnUnitDenyButtonText:    'Abbrechen',
        },
        editUnitsOverlay:               {
            addUnitTitle:                   'Einheit hinzufügen',
            addUnitButton:                  'Hinzufügen',
            editUnitTitle:                  'Einheit bearbeiten',
            editUnitButton:                 'Aktualisieren',
            titlePlaceholder:               'Abteilung Musterdorf',
            addVehicleHint:                 'Hier kannst du eine <strong>Fahrzeugliste</strong> für deine Organisation erstellen. Du kannst zum Beispiel Löschgruppenfahrzeuge, Drehleiter oder Sonderfahrzeuge usw. hinzufügen.',
            deleteVehicleText:              'Sind Sie sicher, dass Sie das Fahrzeug löschen möchten?',
            deleteVehicleConfirmButtonText: 'Löschen',
            deleteVehicleDenyButtonText:    'Abbrechen',
        },
        editParticularitiesOverlay:     {
            addParticularityTitle:                'Besonderheit hinzufügen',
            addParticularity:                     'Besonderheit hinzufügen',
            addParticularityButton:               'Hinzufügen',
            editParticularityTitle:               'Besonderheit bearbeiten',
            editParticularityButton:              'Aktualisieren',
            titlePlaceholder:                     'Einsatz wurde hervorragend abgearbeitet',
            addParticularityHint:                 'Hier kannst du <strong>Besonderheiten</strong> anlegen, welche regelmäßig benötigt werden. Etwa besondere Vorkommnisse oder Maßnahmen.',
            deleteParticularityText:              'Sind Sie sicher, dass Sie die Besonderheit löschen möchten?',
            deleteParticularityConfirmButtonText: 'Löschen',
            deleteParticularityDenyButtonText:    'Abbrechen',
        },
        successfullyTerminationOverlay: {
            confirmTitle: 'Schade dass du gehst!',
            confirmText:  'Deine Kündigung wurde erfolgreich beantragt. In den kommenden Tagen bekommst du eine E-Mail mit dein Kündigungsbestätigung. Bis dahin kannst du Firewriter wie gewohnt nutzen.',
        },
        editVehiclesOverlay:            {
            addVehicleButton:    'Hinzufügen',
            addVehicleTitle:     'Fahrzeug hinzufügen',
            editVehicleTitle:    'Fahrzeug bearbeiten',
            editVehicleButton:   'Aktualisieren',
            titlePlaceholder:    'Fahrzeugtyp (DLK 23/12)',
            subtitlePlaceholder: 'Funkrufnamen',
        },
        editExternalUnitsOverlay:       {
            addExternalUnitTitle:                'Externe Einsatzkraft hinzufügen',
            editExternalUnitTitle:               'Externe Einsatzkraft bearbeiten',
            addExternalUnitButton:               'Hinzufügen',
            editExternalUnitButton:              'Aktualisieren',
            titlePlaceholder:                    'Polizei Musterstadt',
            addExternalUnitHint:                 'Hier kannst du eine <strong>Einheitsliste</strong> für externe Organisationen erstellen. Du kannst zum Beispiel die Polizei, Hilfsorganisationen, Feuerwehren usw. hinzufügen.',
            deleteExternalUnitText:              'Sind Sie sicher, dass Sie die externe Einsatzkraft löschen möchten?',
            deleteExternalUnitConfirmButtonText: 'Löschen',
            deleteExternalUnitDenyButtonText:    'Abbrechen',
        },
        userSettingsOverlay:            {
            changePassword:          'Passwort ändern',
            oldPassword:             'Altes Passwort',
            newPassword:             'Neues Passwort',
            organizationName:        'Name der Organisation',
            organization:            'Organisation aktualisieren',
            notifications:           'Benachrichtigungen anpassen',
            postalCode:              'PLZ/Ort',
            repeatPassword:          'Passwort bestätigen',
            street:                  'Straße',
            updateData:              'Daten aktualisieren',
            updatePassword:          'Passwort aktualisieren',
            passwordMismatch:        'Die Passwörter stimmen nicht überein.',
            loginWooCommerce:        'Mit dem Shop verbinden',
            saveSettings:            'Einstellungen speichern',
            enableEmailNotification: 'E-Mail-Benachrichtigungen aktivieren',
            invoicesAndPlans:        'Pläne und Rechnungen',
        },
        profileSettingsOverlay:         {
            eMail:                   'E-Mail',
            eMailPlaceholder:        'info@feuerwehr-musterstadt.de',
            firstname:               'Vorname',
            firstnamePlaceholder:    'Max',
            lastname:                'Nachname',
            lastnamePlaceholder:     'Mustermann',
            organization:            'Organisation',
            organizationPlaceholder: 'Feuerwehr Musterstadt',
            update:                  'Aktualisieren',
            uploadProfileImage:      'Profilbild hochladen',
        },
        textEditor:                     {
            defaultHeadlineTemplateText: 'Überschrift',
            defaultTextTemplateText:     'Text des Artikels',
            headlineTemplateTextError:   'Bitte passen Sie die Überschrift an.',
            textTemplateTextError:       'Bitte passen Sie den Text an.',
        },
        articleListItem:                {
            pressArticle:     'Presseartikel',
            isGenerating:     'wird gerade generiert.',
            draft:            'Entwurf',
            generationFailed: 'Generierung fehlgeschlagen',
        },
        terminatePaidPlanOverlay:       {
            confirmText:       'Wenn du den Free Plan auswählst, wird ein aktuelles Abo gekündigt. Bist du dir dabei sicher?',
            confirmButtonText: 'Abo kündigen',
            denyButtonText:    'Abbrechen',
        },
        invoicesAndPlans:               {
            plansAndInvoices:          'Pläne und Rechnungen',
            monthlyPayment:            'Monatliche Zahlung',
            yearlyPayment:             'Jährliche Zahlung',
            yearlyDiscountText:        'Bis zu 33% Rabat',
            pricesInclusiveVat:        'Alle Preise inklusive Mehrwertsteuer',
            aboAndPayment:             'Aboverwaltung und Zahlunginformationen',
            currentPlan:               'Dein aktueller Plan',
            changePlan:                'Aktuellen Plan bearbeiten',
            changePlanUrl:             'mein-konto/subscriptions',
            paymentInformation:        'Zahlungsinformationen',
            editPaymentInformation:    'Zahlungsinformationen bearbeiten',
            editPaymentInformationUrl: 'mein-konto/subscriptions',
            invoiceInformation:        'Rechnungsinformationen',
            editInvoiceInformation:    'Rechnungsinformationen bearbeiten',
            editInvoiceInformationUrl: 'mein-konto/edit-address',
            showInvoices:              'Rechnungen anzeigen',
            showInvoicesUrl:           'mein-konto/subscriptions',
            quitAbo:                   'Abo kündigen',
            quitAboHere:               'Abo hier kündigen',
            quitAboHereUrl:            'mein-konto/subscriptions',
        },
        planDisplayItem:                {
            freePlan:                      'Free-Version',
            freePlanText:                  'Zum Testen',
            freePlanUrlMonthly:            '/',
            freePlanUrlYearly:             '/',
            basicPlan:                     'Basic',
            basicPlanText:                 'Für kleine Hilfsorganisationen',
            basicPlanUrlMonthly:           'produkt/firewriter-basic',
            basicPlanUrlYearly:            'produkt/firewriter-basic-2',
            proPlan:                       'Pro',
            proPlanText:                   'Für Hilfsorganisationen aus Mittelstädten',
            proPlanUrlMonthly:             'produkt/firewriter-pro',
            proPlanUrlYearly:              'produkt/firewriter-pro-2',
            premiumPlan:                   'Premium',
            premiumPlanText:               'Für Hilfsorganisationen mit vielen Einsätzen',
            premiumPlanUrlMonthly:         'produkt/firewriter-premium',
            premiumPlanUrlYearly:          'produkt/firewriter-premium-2',
            freePrice:                     'Kostenlos',
            planPriceText:                 '{{price}} Euro',
            perMonthPriceSuffix:           ' / im Monat',
            numberOfPressArticlesPerMonth: '{{number}} Presseartikel im Monat',
            numberOfWordsPerMonth:         '{{number}} Wörter im Monat',
            listExternalUnits:             'Liste "Externe Einsatzkräfte"',
            listUnits:                     'Liste "Eigene Einheiten"',
            listVehicles:                  'Liste "Einsatzfahrzeuge"',
            numberOfEntries:               '{{number}} Einträge',
            infiniteEntries:               'unbeschränkt Einträge',
            adFree:                        'Werbefrei',
            support:                       'Support per Mail/Telefon',
            selectNow:                     'Jetzt Auswählen ',
            recommended:                   'Sehr beliebt!',
        },
        listEntryCounter:               {
            numberOfEntriesText: 'Du hast {{numberOfEntries}} von {{numericLimit}} Einträgen erstellt.',
            limitReachedText:    'Wenn du mehr Einträge erstellen möchtest, kannst du deinen Plan jederzeit upgraden.',
            limitExceededText:   'Du hast zu viele Einträge erstellt. Bitte lösche einige Einträge um die vorhandenen zu bearbeiten oder neue zu erstellen.',
            freePlanText:        'In der Free-Version stehen dir keine Einträge zur Verfügung. Stufe deinen Plan hoch um Einträge anzulegen und diese in den Presseberichten zu nutzen.',
        },
    },
});

export default german;
