//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import classNames      from 'classnames';
import I18n            from 'i18next';
import _               from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Route }       from 'react-router';
import { Switch }      from 'react-router';
import { Redirect }    from 'react-router';

import OverlayManager      from '@connected/OverlayManager';
import Overlay             from '@constants/Overlay';
import Routes              from '@constants/Routes';
import RouteHelper         from '@helper/Route';
import ActivateTestAccount from '@screens/ActivateTestAccount';
import AdvertisementTest   from '@screens/AdvertisementTest';
import ExternalTaskForces  from '@screens/ExternalTaskForces';
import Home                from '@screens/Home';
import InvoicesAndPlans    from '@screens/InvoicesAndPlans';
import Login               from '@screens/Login';
import LostPassword        from '@screens/LostPassword';
import NotFound            from '@screens/NotFound';
import RegisterTestAccount from '@screens/RegisterTestAccount';
import ResetPassword       from '@screens/ResetPassword';
import { AlertBoxActions } from '@slices/alertBox';
import { OverlayActions }  from '@slices/overlay';
import MobileHeader        from '@stateless/composed/MobileHeader';
import NotificationHandler from '@stateless/composed/NotificationHandler';
import PageTitle           from '@stateless/composed/PageTitle';
import selectOverlay       from '@store/selectors/overlay';
import selectPathname      from '@store/selectors/router';
import selectToken         from '@store/selectors/user';

import styles from '../../../styles.module.scss';

const routesWithoutHeader = [
    Routes.login,
    Routes.activateTestAccount,
    Routes.registerTestAccount,
    Routes.advertisementTest, // TODO: https://lulububu.atlassian.net/browse/FIREWRITER-817
];

const MainRouter = () => {
    const dispatch       = useDispatch();
    const path           = useSelector(selectPathname);
    const token          = useSelector(selectToken);
    const currentOverlay = useSelector(selectOverlay);
    const routeName      = RouteHelper.getRouteFromPath(path, true);

    useEffect(() => {
        if (
            currentOverlay
        ) {
            const scrollTop = document.documentElement.scrollTop;

            document.body.style.setProperty(
                '--st',
                `${-(
                    scrollTop
                )}px`,
            );
            document.body.classList.add('no-scroll');

            return () => {
                document.body.classList.remove('no-scroll');
                document.documentElement.scrollTop = scrollTop;
            };
        }

        return _.noop;
    }, [currentOverlay]);

    function mobileMenuClicked() {
        dispatch(OverlayActions.openOverlay({
            overlay: Overlay.mobileMenu,
        }));
    }

    function mobileCloseMenuClicked() {
        if (currentOverlay === Overlay.createPressArticle) {
            dispatch(OverlayActions.openOverlay({
                overlay: Overlay.saveDraft,
            }));
            dispatch(AlertBoxActions.alertBoxLocationChange());

            return;
        }

        dispatch(OverlayActions.closeOverlay());
        dispatch(AlertBoxActions.alertBoxLocationChange());
    }

    function renderLogin() {
        if (token) {
            return (
                <Redirect to={Routes.home} />
            );
        }

        return (
            <Login />
        );
    }

    function renderPageTitle() {
        return (
            <PageTitle title={I18n.t(`page${routeName}`)} />
        );
    }

    function renderHeader() {
        if (!routesWithoutHeader.includes(path)) {
            return (
                <MobileHeader
                    menuClicked={mobileMenuClicked}
                    closeMenuClicked={mobileCloseMenuClicked}
                />
            );
        }

        return null;
    }

    return (
        <>
            {renderPageTitle()}
            {renderHeader()}
            <NotificationHandler />
            <div
                className={classNames(
                    styles.appContent,
                    {
                        [styles.overlayOpen]: currentOverlay,
                    },
                )}
            >
                <OverlayManager />
                <Switch>
                    <Route
                        exact
                        path={Routes.login}
                        component={Login}
                    >
                        {renderLogin()}
                    </Route>
                    <Route
                        exact
                        path={Routes.resetPassword}
                        component={ResetPassword}
                    />
                    <Route
                        exact
                        path={Routes.lostPassword}
                        component={LostPassword}
                    />
                    <Route
                        exact
                        path={Routes.activateTestAccount}
                        component={ActivateTestAccount}
                    />
                    <Route
                        exact
                        path={Routes.registerTestAccount}
                        component={RegisterTestAccount}
                    />
                    <Route
                        exact
                        path={Routes.home}
                        component={Home}
                    />
                    <Route
                        exact
                        path={Routes.externalTaskForces}
                        component={ExternalTaskForces}
                    />
                    <Route
                        exact
                        path={Routes.invoicesAndPlans}
                        component={InvoicesAndPlans}
                    />
                    {/* TODO: https://lulububu.atlassian.net/browse/FIREWRITER-817 */}
                    <Route
                        exact
                        path={Routes.advertisementTest}
                        component={AdvertisementTest}
                    />
                    <Route component={NotFound} />
                </Switch>
            </div>
        </>
    );
};

export default MainRouter;
